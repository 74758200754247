.ModalActivationCode{
    position: relative;
    width: 100%;
    padding: 0px 0px 20px 0px;
}

.activationCodeListRoot_v{
    width: calc(100% - 32px);
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 16px;
}

.activationCodeLine{
    width: 100%;
    margin-top: 16px;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.activationCodeLine_v{
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ModalActivationCode .codeIdPart{
    margin-left: 17px;
    width: 97px;
    text-align: left;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ModalActivationCode .codePart{
    width: 200px;
    text-align: left;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ModalActivationCode .statusPart{
    margin-left: 5px;
    width: 88px;
    text-align: center;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ModalActivationCode .datePart{
    margin-left: 15px;
    width: 140px;
    text-align: center;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activationCodeList{
    margin-top: 6px;
    width: 100%;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 16px;
}

.activationCodeList_v{
    margin-top: 6px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}

.activationCodeListLine{
    width: 100%;
    min-height: 32px;
    border-top: 1px solid rgba(51,51,51,1);
}

.activationCodeActiveState{
    width: 68px;
    height: 20px;
    background-color: #1A1A1A;
    border: 1px solid rgba(253,209,15,1);
    border-radius: 8px;
    color: var(--yellow);
}
