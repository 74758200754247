.ModalMintLoginNFT{
    padding: 44px 0px 38px;
}

.mintLoginNFTRoot{
    margin-top: 12px;
    width: 260px;
    padding: 10px 0px 16px;
    background-color: rgba(0,0,0,1);
    border-radius: 8px;
}

.mintLoginNFTBtn{
    margin-top: 19px;
    width: 136px;
    height: 35px;
    border-radius: 8px;
    background-color: var(--yellow);
    font-size: 20px;
    color: #333333;
    font-weight: 700;
}

.mintLoginGetMoreBtn{
    margin-top: 19px;
    width: 136px;
    height: 35px;
    border-radius: 8px;
    background: #1A1A1A;
    border: 1px solid rgba(255,255,255,1);
    font-size: 20px;
    color: #FFFFFF;
    font-weight: 700;
}
