.ModalWithdrawHistory{
    padding: 0px 0px 40px;
}

.withdrawHistoryListRoot_v{
    width: 90%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 16px;
}

.withdrawHistoryLine{
    width: 100%;
    margin-top: 16px;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.withdrawHistoryLine_v{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ModalWithdrawHistory .statusPart{
    margin-left: 18px;
    width: 38px;
    text-align: center;
    font-size: 12px;
}

.ModalWithdrawHistory .amountPart{
    width: 70px;
    text-align: center;
    font-size: 12px;
    margin: 0px 6px 0px 14px;
}

.ModalWithdrawHistory .reviewPart{
    width: 140px;
    text-align: center;
    font-size: 12px;
}

.ModalWithdrawHistory .completePart{
    width: 140px;
    text-align: center;
    font-size: 12px;
}

.ModalWithdrawHistory .toAddressPart{
    width: 108px;
    text-align: center;
    font-size: 12px;
}

.ModalWithdrawHistory .txnPart{
    width: 40px;
    text-align: center;
    font-size: 12px;
}

.withdrawHistoryList{
    margin-top: 6px;
    width: 100%;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 16px;
}

.withdrawHistoryList_v{
    margin-top: 6px;
    max-height: 200px;
    overflow-y: auto;
}

.withdrawHistoryListLine{
    width: 100%;
    min-height: 32px;
    border-top: 1px solid rgba(51,51,51,1);
}
