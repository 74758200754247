.historyTransactionListRoot_v{
    width: calc(100% - 32px);
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 16px;
}

.transactionListoryLine{
    width: 100%;
    margin-top: 16px;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.transactionListoryLine_v{
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ModalDistribute .chainPart{
    margin-left: 17px;
    width: 80px;
    text-align: left;
    font-size: 12px;
}

.ModalDistribute .walletPart{
    margin-left: 5px;
    width: 110px;
    text-align: left;
    font-size: 12px;
}

.ModalDistribute .amountPart{
    margin-left: 5px;
    width: 115px;
    text-align: center;
    font-size: 12px;
}

.ModalDistribute .datePart{
    margin-left: 5px;
    width: 120px;
    text-align: center;
    font-size: 12px;
}

.ModalDistribute .txnPart{
    margin-left: 5px;
    width: 115px;
    text-align: center;
    font-size: 12px;
}

.historyTransactionList{
    margin-top: 6px;
    width: 100%;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 16px;
}

.historyTransactionList_v{
    margin-top: 6px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}

.historyTransactionListLine{
    width: 100%;
    min-height: 32px;
    border-top: 1px solid rgba(51,51,51,1);
}

.amountPartIconRoot{
    width: 32px;
    height: 100%;
    position: relative;
    margin-right: 2px;
}

.amountPartIcon1{
    position: absolute;
    right: 0px;
    transform: translateY(-50%);
    top: 50%;
}

.amountPartIcon2{
    position: absolute;
    left: 0px;
    transform: translateY(-50%);
    top: 50%;
}