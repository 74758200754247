.ModalBecomeLeader{
    position: relative;
    width: 100%;
}

.becomeLeaderInput{
    width: 294px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent !important;
    color: #fff;
    border-color: var(--border-color) !important;
    border-radius: 4px;
    margin-top: 30px;
}

.becomeLeaderBtn{
    margin-bottom: 44px;
    width: 122px;
    height: 35px;
    background: var(--yellow);
    border-radius: 8px;
    font-size: 20px;
    font-weight: 700;
    color: #333333;
}

.becomeLeaderBtn_disable{
    margin-bottom: 44px;
    width: 122px;
    height: 35px;
    background: #333333;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 700;
    color: #555555;
}

.ModalBecomeLeaderErrorMsg{
    width: 100%;
    height: 46px;
    font-size: 12px;
    color: var(--yellow);
}
