.ModalHistoryCommission{
    padding: 0px 0px 40px;
}

.historyCommissionListRoot_v{
    width: 90%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 16px;
}

.historyCommissionLine{
    width: 100%;
    margin-top: 16px;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.historyCommissionLine_v{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ModalHistoryCommission .timePart{
    margin-left: 18px;
    width: 127px;
    text-align: left;
    font-size: 12px;
}

.ModalHistoryCommission .volumePart{
    margin-left: 5px;
    width: 100px;
    text-align: center;
    font-size: 12px;
}

.ModalHistoryCommission .tierPart{
    margin-left: 5px;
    width: 74px;
    text-align: center;
    font-size: 12px;
}

.ModalHistoryCommission .pricePart{
    margin-left: 5px;
    width: 120px;
    text-align: center;
    font-size: 12px;
}

.ModalHistoryCommission .earningsPart{
    margin-left: 5px;
    width: 120px;
    text-align: center;
    font-size: 12px;
}

.historyCommissionList{
    margin-top: 6px;
    width: 100%;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 16px;
}

.historyCommissionList_v{
    margin-top: 6px;
    max-height: 200px;
    overflow-y: auto;
}

.historyCommissionListLine{
    width: 100%;
    min-height: 32px;
    border-top: 1px solid rgba(51,51,51,1);
}
