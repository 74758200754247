
.icon-wrapper {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
.customIcon{
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  
}

.customIconRotate{
  animation: customIconRotateAni 0.5s linear infinite;
}

@keyframes customIconRotateAni{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

/* 
// img{
//   width: 100%;
//   height: 100%;
// } */