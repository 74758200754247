.wrap {
    position: absolute;
    /* background-color: rgba(255, 255, 255, .5); */
    /* width: 100vw;
    height: 100vh; */
    color: #fff;
    right: 30px;
    top: 22px;
    z-index: 999;
}

/* .login {
    display: flex;
    align-items: center;
    justify-content: center;
} */
.login {
    width: 180px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-grey);
    border-radius: 10px;
    padding: 0 18px;
}
.retryBtn{
    margin-left: 10px;
    font-size: 18px;
    color: #ccc;
    text-decoration: underline;
}
.retryBtn2{
    margin-left: 6px;
    font-size: 12px;
    color: #999999;
    text-decoration: underline;
}
.login img {
    width: 22px;
    height: 22px;
}

.infoWrap .mr5 {
    margin-right: 5px;
}

.infoWrap .fs16 {
    font-size: 16px;
}

.drawerWrap {
    position: relative;
    padding: 15px;
    color: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.drawerWrap .mid {
    border-top: 1px solid rgba(51, 51, 51, 1);
    /*border-bottom: 1px solid rgba(51, 51, 51, 1);*/
    /*padding: 15px 0;*/
    overflow-x: hidden;
    overflow-y: auto;
}

.drawerWrap .mid .bindBtn {
    width: 100%;
    width: 163px;
    height: 33px;
    border-radius: 20px;
    background: #1F1F1E;
    border: 1px solid rgba(85, 85, 85, 1);
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drawerWrap .block {
    position: relative;
    padding: 16px 15px 16px;
}

.blockItem{
    min-height: 60px;
    max-height: 60px;
}

.drawerWrap .block:not(.infoHead):hover {
    background-color: #3D3D3D;
}

.drawerWrap .block .yellowTag {
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 50%;
    background-color: var(--yellow);
    transform: translateY(-50%);
    border-radius: 50%;
}

.drawerWrap .block.border-bottom {
    border-bottom: 1px solid rgba(51, 51, 51, 1);
}

.drawerWrap .block.border-top {
    border-top: 1px solid rgba(51, 51, 51, 1);
}

.input {
    width: 100%;
    border-color: var(--border-color);
    height: 46px;
    line-height: 46px;
    border-radius: 4px;
    background: transparent !important;
    border-color: var(--border-color) !important;
    color: #fff;
    font-size: 16px;
}

.input.error {
    border-color: var(--yellow) !important;
}

input::placeholder {
    color: #999 !important;
}

.list {
    padding: 20px 30px;
    width: 100%;
    border-top: 1px solid var(--border-color);
    max-height: 200px;
    overflow-y: auto;
}

.list .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    margin-top: 60px;
    margin-bottom: 30px;
}

.checkWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.checkBtn {
    margin: 20px 0 20px;

}

.checkBtn button {
    min-width: 125px;
    padding: 0 20px;
    height: 35px;
}

.errorTips {
    width: 100%;
    height: 14px;
}

.succBtn {
    width: 88px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: transparent;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 20px;
}

.referralTitle {
    border-top: 1px solid var(--border-color);
    padding: 15px 0;
    width: 100%;

}

.referralList {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.referralList .referralItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.myReferrer {
    display: flex;
    width: 90%;
    padding: 12px 20px 15px 20px;
    margin: 5px 20px 10px 20px;
    background: #111111;
    border-radius: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.momentDrawer .fs16,
.confirmModalWrap .fs16 {
    font-size: 16px;
}

.momentDrawer .fs20,
.confirmModalWrap .fs20 {
    font-size: 20px;
}

.momentDrawer .fs12,
.confirmModalWrap .fs12 {
    font-size: 12px;
}

.ant-modal.confirmModalWrap .ant-modal-footer {
    display: none;
}

.ant-modal.confirmModalWrap .ant-modal-header {
    background-color: transparent;
    width: 100%;
    height: 95px;
    margin-bottom: 0px;
    display: flex;
    align-items: start;
    justify-content: center;
}

.ant-modal.confirmModalWrap .ant-modal-title {
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
    color: var(--white);
}

.ant-modal.confirmModalWrap .ant-modal-close {
    top: 17px;
    inset-inline-end: 18px;
}

.ant-modal.confirmModalWrap .ant-modal-content {
    padding: 37px !important;
    border-radius: 0px;
    background-color: var(--gray);
    color: #fff;
    border-radius: 23px;
}


.confirmModalWrap.ant-modal .ant-modal-close .ant-modal-close-x {
    display: none;
}

.confirmModalWrap.ant-modal .ant-modal-close {
    width: 23px;
    height: 23px;
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
}

.btn_public,
.btn_public:focus-visible {
    font-size: 20px;
}

@media (max-width:1399px){
    .login {
        width: 140px;
        height: 48px;
    }
}

@media (max-width:1199px){

}

@media (max-width:991px){
    .wrap {
        right: 50px;
        top: 11px;
    }
    .login {
        width: 100px;
        height: 38px;
    }
    .login div{
        font-size: 16px !important;
    }
}

.menuChainRoot{
    width: 100%;
    border-top: 1px solid rgba(51, 51, 51, 1);
    padding: 11px 0px 7px;
}

.chooseChainBtn{
    width: 100%;
    height: 32px;
    background: #111111;
    border-radius: 8px;
    padding: 0px 8px 0px 16px;
}

.chooseChainList{
    width: 100%;
    max-height: 150px;
    background: #111111;
    border-radius: 8px;
}

.chooseChainItem{
    width: 100%;
    min-height: 32px;
    height: 32px;
    padding: 0px 8px 0px 16px;
}

.chooseChainListScroll{
    width: 100%;
    max-height: 118px;
    overflow-x: hidden;
    overflow-y: auto;
}

.drawPanel{
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    position: absolute;
    background: var(--gray);
    overflow-x: hidden;
    overflow-y: auto;
    top: 0px;
    left: 0px;
}

.drawPanelContent{
    width: 300px;
    padding: 13px 14px 0px 16px;
}

.sendInput{
    width: calc(100% - 44px);
    margin: 10px 0px;
    border-color: var(--border-color);
    height: 33px;
    line-height: 33px;
    border-radius: 8px;
    background: transparent !important;
    border-color: var(--border-color) !important;
    color: #fff;
    font-size: 12px;
    text-align: center;
}

.sendPanelBtn{
    width: 122px;
    height: 35px;
    background-color: var(--yellow);
    height: 35px;
    border-radius: 8px;
    margin-top: 50px;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
}

.sendPanelBtn_disable{
    width: 122px;
    height: 35px;
    background-color: #333333;
    height: 35px;
    border-radius: 8px;
    margin-top: 50px;
    font-size: 16px;
    color: #555555;
    font-weight: bold;
}

.depositAddressRoot{
    width: 100%;
    height: 150px;
    background-color: #111111;
    border-radius: 8px;
    margin-top: 25px;
}

.depositAddressText{
    max-width: calc(100% - 60px);
    color: #999999;
    font-weight: 400;
    word-wrap: break-word;
    text-align: center;
}

.foldLine{
    height: 36px;
    padding: 0px 8px 0px 15px;
    background-color: #000000;
    border-radius: 8px;
}

.openFoldLine{
    padding: 5px 8px 10px 15px;
    background-color: #000000;
    border-radius: 8px;
}

.bindFriendCodeBtn{
    width: 252px;
    height: 35px;
    background: #1A1A1A;
    border: 1px solid rgba(255,255,255,1);
    border-radius: 8px;
}

.bindFriendCodeBtn:hover{
    background: rgba(255,255,255,1);
    color: #333333;
}

.referralGourpTitle {
    border-top: 1px solid var(--border-color);
    padding: 30px 0px 12px;
    width: 100%;
}

.bindFriendLine{
    margin-top: 30px;
    margin-bottom: 30px;
    width: calc(100% - 72px);
    height: 1px;
    border-bottom: 1px solid var(--border-color);
}

.bindReferralCodeBtn{
    border-bottom: 1px solid #999999;
    line-height: 1.1;
    cursor: pointer;
}

.referralCodeInput{
    width: 60%;
    border-color: var(--border-color);
    height: 46px;
    line-height: 46px;
    border-radius: 4px;
    background: transparent !important;
    border-color: var(--border-color) !important;
    color: #fff;
    font-size: 14px;
    position: relative;
    left: -20px;
}

.bindReferralCodeBtnRoot{
    position: absolute;
    left: 300px;
}

.bindReferralCodeBtnTips{
    position: absolute;
    left: 60px;
    top: -25px;
    color: #999999;
}

.shareReferralCodeBtn{
    width: 122px;
    height: 35px;
    background-color: var(--yellow);
    height: 35px;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
}
