


:root{
  --yellow:#FDD10F;
  --gray:#1A1A1A;

  --font-common: "Roboto", Arial, Helvetica, sans-serif;
}
.fontCommon{
  font-family: var(--font-common) !important;
}
.color-yellow{
  color: var(--yellow) !important;
}
.color-gray{
  color: var(--gray) !important;
}

.color-999{
  color: #999 !important;
}
.op5{
  opacity: .5;
}
.f1{
  flex: 1;
}

.tlc{
  text-align: center;
}

.tls{
  text-align: start;
}

.tle{
  text-align: end;
}

.flex_center_start{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex_center_end{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex_center_between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_center_start_col{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.flex_center_between_col{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.momentDrawerWarp .momentDrawer{
  background-color: var(--gray);
  /* border-top: 2px solid var(--TP-blue-light); */
}

.momentDrawerWarp .momentDrawer .ant-drawer-body{
  padding: 0;
}
.plr15{
  padding: 0 15px;
}
.mtb20{
  margin: 20px 0;
}

.forceWordBreak{
  word-break: break-all;
}

.nowrap{
  white-space: nowrap;
}

/* @font-face {
  font-family: "Helvetica";
  src: url(./assets/font/helvetica-neue-regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url(./assets/font/HelveticaNeueBold.otf) format("truetype");
  font-weight: bold;
} */

*{
  margin: 0;
  padding: 0;
  /* font-family: 'HelveticaEegular','Regular',"Arial"; */
  box-sizing: border-box;
  
}

body{
  background-color: #000;
  color: #fff;
  /* min-width: 1600px; */
  overflow-x: hidden;
}
:root {
  --yellow: #FDD110;
  --gray: #1A1A1A;
  --border-color: rgb(51, 51, 51);
  --dark-grey: #2E2E2E;
  --light-grey: #B9B9B9;
}

::-webkit-scrollbar {
  width: 2px;
  background-color: #000;
  display: block;
}
::-webkit-scrollbar-track {
  background: #aaa; /* 滚动条轨道背景色 */
}
::-webkit-scrollbar-thumb {
  background: #aaa;
}
.hideScorllBar::-webkit-scrollbar{
  display: none;
}
.w100p{
  width: 100%;
}
.h100p{
  height: 100%;
}
.textstyle-none{
  text-decoration:none
}
.color-yellow {
  color: var(--yellow) !important;
}

.color-gray {
  color: var(--gray) !important;
}

.color-light-gray{
  color: var(--light-grey) !important;
}

.color-999 {
  color: #999 !important;
}

.color-bbb {
  color: #bbb !important;
}

.color-000 {
  color: #000 !important;
}

.f1 {
  flex: 1;
}

.fb{
  font-weight: bold;
}
.fs10 {
  font-size: 10px;
}
.fs12 {
  font-size: 12px;
}
.fs14{
  font-size: 14px;
}
.fs16 {
  font-size: 16px;
}
.fs18 {
  font-size: 18px;
}
.fs20 {
  font-size: 20px;
}
.fs24 {
  font-size: 24px;
}
.fs26{
  font-size: 26px;
}
.fs30{
  font-size: 30px;
}
.fs32{
  font-size: 32px;
}
.fs48{
  font-size: 48px;
}
.fs71{
  font-size: 71px;
}
.fs80 {
  font-size: 80px;
}
.fs89{
  font-size: 89px;
  font-weight: 400;
}
.fs98{
  font-size: 98px;
  font-weight: 400;
}
.fs116{
  font-size: 116px;
}

.fs178{
  font-size: 142px;
}

.fs178{
  font-size: 178px;
}

.pl40{
  padding-left: 40px;
}

.flex_center_start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.flex_center_center {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.flex_center_center_col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex_start_start{
  display: flex;
  align-items: start;
  justify-content: flex-start;
}

.flex_col {
  flex-direction: column;
}


.flex_center_end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex_center_end_col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.flex_center_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_center_evenly {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.modalCloseBtn{
  position: absolute;
  top: 16px;
  right: 16px;
}

.confirmModalWrap.ant-modal .ant-modal-close{
  background-image: url(../public/img/UI_Picture_Icon_Close_01.png);
  width: 22px;
  height: 22px;
}

.momentDrawerWarp .momentDrawer {
  background-color: var(--gray);
}

.momentDrawerWarp .momentDrawer .ant-drawer-body {
  padding: 0;
}

.mb40 {
  margin-bottom: 40px;
}

.ml1 {
  margin-left: 1px;
}
.ml2 {
  margin-left: 2px;
}
.ml3 {
  margin-left: 3px;
}
.ml4 {
  margin-left: 4px;
}
.ml5 {
  margin-left: 5px;
}
.ml6 {
  margin-left: 6px;
}
.ml7 {
  margin-left: 7px;
}
.ml8 {
  margin-left: 8px;
}
.ml9 {
  margin-left: 9px;
}
.ml10 {
  margin-left: 10px;
}
.ml11 {
  margin-left: 11px;
}
.ml12 {
  margin-left: 12px;
}
.ml13 {
  margin-left: 13px;
}
.ml14 {
  margin-left: 14px;
}
.ml15 {
  margin-left: 15px;
}
.ml16 {
  margin-left: 16px;
}
.ml17 {
  margin-left: 17px;
}
.ml18 {
  margin-left: 18px;
}
.ml19 {
  margin-left: 19px;
}
.ml20 {
  margin-left: 20px;
}
.ml21 {
  margin-left: 21px;
}
.ml22 {
  margin-left: 22px;
}
.ml23 {
  margin-left: 23px;
}
.ml24 {
  margin-left: 24px;
}
.ml25 {
  margin-left: 25px;
}
.ml26 {
  margin-left: 26px;
}
.ml27 {
  margin-left: 27px;
}
.ml28 {
  margin-left: 28px;
}
.ml29 {
  margin-left: 29px;
}
.ml30 {
  margin-left: 30px;
}

.mr1 {
  margin-right: 1px;
}
.mr2 {
  margin-right: 2px;
}
.mr3 {
  margin-right: 3px;
}
.mr4 {
  margin-right: 4px;
}
.mr5 {
  margin-right: 5px;
}
.mr6 {
  margin-right: 6px;
}
.mr7 {
  margin-right: 7px;
}
.mr8 {
  margin-right: 8px;
}
.mr9 {
  margin-right: 9px;
}
.mr10 {
  margin-right: 10px;
}
.mr11 {
  margin-right: 11px;
}
.mr12 {
  margin-right: 12px;
}
.mr13 {
  margin-right: 13px;
}
.mr14 {
  margin-right: 14px;
}
.mr15 {
  margin-right: 15px;
}
.mr16 {
  margin-right: 16px;
}
.mr17 {
  margin-right: 17px;
}
.mr18 {
  margin-right: 18px;
}
.mr19 {
  margin-right: 19px;
}
.mr20 {
  margin-right: 20px;
}
.mr21 {
  margin-right: 21px;
}
.mr22 {
  margin-right: 22px;
}
.mr23 {
  margin-right: 23px;
}
.mr24 {
  margin-right: 24px;
}
.mr25 {
  margin-right: 25px;
}
.mr26 {
  margin-right: 26px;
}
.mr27 {
  margin-right: 27px;
}
.mr28 {
  margin-right: 28px;
}
.mr29 {
  margin-right: 29px;
}
.mr30{
  margin-right: 30px;
}
.mr50 {
  margin-right: 50px;
}
.mr100{
  margin-right: 100px;
}
.plr15 {
  padding: 0 15px;
}

.mtb20 {
  margin: 20px 0;
}

.mt1 {
  margin-top: 1px;
}
.mt2 {
  margin-top: 2px;
}
.mt3 {
  margin-top: 3px;
}
.mt4 {
  margin-top: 4px;
}
.mt5 {
  margin-top: 5px;
}
.mt6 {
  margin-top: 6px;
}
.mt7 {
  margin-top: 7px;
}
.mt8 {
  margin-top: 8px;
}
.mt9 {
  margin-top: 9px;
}
.mt10 {
  margin-top: 10px;
}
.mt11 {
  margin-top: 11px;
}
.mt12 {
  margin-top: 12px;
}
.mt13 {
  margin-top: 13px;
}
.mt14 {
  margin-top: 14px;
}
.mt15 {
  margin-top: 15px;
}
.mt16 {
  margin-top: 16px;
}
.mt17 {
  margin-top: 17px;
}
.mt18 {
  margin-top: 18px;
}
.mt19 {
  margin-top: 19px;
}
.mt20 {
  margin-top: 20px;
}
.mt21 {
  margin-top: 21px;
}
.mt22 {
  margin-top: 22px;
}
.mt23 {
  margin-top: 23px;
}
.mt24 {
  margin-top: 24px;
}
.mt25 {
  margin-top: 25px;
}
.mt26 {
  margin-top: 26px;
}
.mt27 {
  margin-top: 27px;
}
.mt28 {
  margin-top: 28px;
}
.mt29 {
  margin-top: 29px;
}
.mt30 {
  margin-top: 30px;
}
.mt40{
  margin-top: 40px;
}

.mt50{
  margin-top: 50px;
}

.mt80{
  margin-top: 80px;
}
.mt100{
  margin-top: 100px;
}
.mt110{
  margin-top: 110px;
}
.mb80{
  margin-bottom: 80px;
} 

.mt130{
  margin-top: 130px;
}

.mb1 {
  margin-bottom: 1px;
}
.mb2 {
  margin-bottom: 2px;
}
.mb3 {
  margin-bottom: 3px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb6 {
  margin-bottom: 6px;
}
.mb7 {
  margin-bottom: 7px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb9 {
  margin-bottom: 9px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb11 {
  margin-bottom: 11px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb13 {
  margin-bottom: 13px;
}
.mb14 {
  margin-bottom: 14px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb17 {
  margin-bottom: 17px;
}
.mb18 {
  margin-bottom: 18px;
}
.mb19 {
  margin-bottom: 19px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb21 {
  margin-bottom: 21px;
}
.mb22 {
  margin-bottom: 22px;
}
.mb23 {
  margin-bottom: 23px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb26 {
  margin-bottom: 26px;
}
.mb27 {
  margin-bottom: 27px;
}
.mb28 {
  margin-bottom: 28px;
}
.mb29 {
  margin-bottom: 29px;
}
.mb30 {
  margin-bottom: 30px;
}

.mb170{
  margin-bottom: 170px;
}
.mb90{
  margin-bottom: 90px;
} 
.mb30{
  margin-bottom: 30px;
}

.mb45{
  margin-bottom: 45px;
}

.mb100{
  margin-bottom: 100px;
}

.lh1{
  line-height: 1;
}

/* 
.ant-modal.confirmModalWrap .ant-modal-footer {
  display: none;
}

.ant-modal.confirmModalWrap .ant-modal-header {
  background-color: transparent;
  width: 100%;
  height: 95px;
  margin-bottom: 0px;
  display: flex;
  align-items: start;
  justify-content: center;
}

.ant-modal.confirmModalWrap .ant-modal-title {
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  color: var(--white);
}

.ant-modal.confirmModalWrap .ant-modal-close {
  top: 17px;
  inset-inline-end: 18px;
}

.ant-modal.confirmModalWrap .ant-modal-content {
  padding: 37px !important;
  border-radius: 0px;
  background-color: var(--gray);
  color: #fff;
  border-radius: 23px;
}


.confirmModalWrap.ant-modal .ant-modal-close .ant-modal-close-x {
  display: none;
}

.confirmModalWrap.ant-modal .ant-modal-close {
  width: 23px;
  height: 23px;
  background-image: url(../public/img/UI_Picture_Icon_Close_01.png);
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
} */

.btn_public,
.btn_public:focus-visible {
  height: auto;
  outline: none !important;
  font-size: 20px;
  width: 100%;
  background: var(--yellow);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_public span,
.btn_public:focus-visible span {
  color: var(--black);
  font-weight: bold;
}

.btn_public.widthAuto,
.btn_public.widthAuto:focus-visible {
  width: auto;
}


.btn_public span,
.btn_public:focus-visible span {
  color: var(--black);
}

.btn_public.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
  color: #000;
  background: var(--yellow);
}

.ant-btn-default:disabled {
  background-color: #333333;
}
.ant-btn-default:disabled span {
  color: #555555;
}
.pointer{
  cursor: pointer;
}
.swiper.swiper-initialized.upSwiper{
  padding-bottom: 70px;
}
.upSwiper .swiper-pagination-bullet{
  width: 20px;
  height: 20px; 
  background-color: #000;
}
.upSwiper .swiper-pagination-bullet-active{
  background-color: #000;
}
.upSwiper.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
  margin: 0 15px;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

input[type="number"]{
  -moz-appearance: textfield;
}

.ant-modal.modalnopadding.confirmModalWrap .ant-modal-content{
  padding: 0px !important;
}

.op4{
  opacity: 0.4;
}

.op6{
  opacity: 0.6;
}

.posRelative{
  position: relative;
}

.comfirmbtn{
  background: var(--yellow);
  border-radius: 8px;
  color: #333333;
}

.infinite-scroll-component__outerdiv{
  width: 100%;
}
